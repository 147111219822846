import { Card, Container } from 'react-bootstrap';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';


import Link from 'next/link';
import MarketingLayout from '../components/marketing-site/MarketingLayout';
import AnimatedComponent from '../components/marketing-site/animated-component/AnimatedComponent';
import ListingItem from '../components/marketplace/list/ListingItem';
import { getPublishedListingsPath, initPath } from '../lib/api/Api';
import { agendaRightArrow, notFullStarIcon, playIcon, starIcon } from '../lib/svg-icons/SvgIcons';
import axios from 'axios';
import absoluteUrl from 'next-absolute-url';
import { NextSeo } from 'next-seo';

export async function getServerSideProps(context: any) {
    const { origin } = absoluteUrl(context.req);
    const listingPromise = await axios({
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        url: origin + getPublishedListingsPath
    });

    const initDataPromise = await axios({
        method: 'GET',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        },
        url: `${origin}${initPath}`
    });

    const [listings, initDataResponse] = await Promise.all([listingPromise, initDataPromise]);

    return {
        props: {
            latestVehicles: listings.data.splice(0, 4),
            initData: initDataResponse.data
        }
    }
}

interface AppProps {
    latestVehicles: any[];
}

function App({latestVehicles}: AppProps) {
    const scrollToPurringCustomer = () => {
        const posTop = document.getElementById('purring-customers')?.offsetTop;
        posTop && window.scrollTo({
            top: posTop,
            behavior: 'smooth'
        });
    }

    const scrollToHowItWorks = () => {
        const posTop = document.getElementById('how-it-works')?.offsetTop;
        posTop && window.scrollTo({
            top: posTop,
            behavior: 'smooth'
        });
    }

    return (
        <>
            <NextSeo description="Discover a safer, simpler way to buy and sell cars - Choose Purr for trusted transactions, expert guidance, and hassle-free negotiations." />
            <MarketingLayout>
                <div className='homepage'>
                    <section className='bg-gray intro-section'>
                        <Container fluid='lg'>
                            <div className="main-content">
                                <div className="hero-text">
                                    <h1>
                                        The <span className="underline">simplest</span> way to buy or sell a used car.
                                    </h1>
                                    <p className="mt-4 font-20">Purr is a marketplace that simplifies the car buying and selling process - connecting buyers, sellers, and dealers in Canada for a seamless transaction experience. </p>
                                </div>
                                <div className="hero-container">
                                    <div className="hero">
                                    <AnimatedComponent path='/marketing-assets/animations/heros/seller.json'/>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </section>
                    <section className='social-section'>
                        <div className='bg-white review cursor-pointer' onClick={scrollToPurringCustomer}>
                            <div className='d-flex align-items-center gap-1'>
                                {starIcon}
                                {starIcon}
                                {starIcon}
                                {starIcon}
                                {notFullStarIcon}
                            </div>
                            <span className='rating-text'>4.8 overall rating</span>
                        </div>
                        <div className='social-network'>
                            <div className="footer-social-links">
                                <div className='watch-how-it-works' onClick={scrollToHowItWorks}>
                                    {playIcon}
                                    Watch how it works
                                </div>
                                <a href="https://www.instagram.com/purr.ca" target="_blank">
                                    <img width="33" src="/marketing-assets/images/social/PURR_SM_Icons-Instagram-Black.svg" />
                                </a>
                                <a href="https://www.facebook.com/Purr-103458345622743" target="_blank">
                                    <img width="33" src="/marketing-assets/images/social/PURR_SM_Icons-Facebook-Black.svg" />
                                </a>
                                <a href="https://www.linkedin.com/company/purr-ca/about/?viewAsMember=true" target="_blank">
                                    <img width="33" src="/marketing-assets/images/social/PURR_SM_Icons-Linkedin-Black.svg" />
                                </a>
                            </div>
                        </div>
                    </section>
                    <section className='bg-white purring-customers above-cat purring-customers-section' id='purring-customers'>
                        <Container fluid='lg' className='purring-customers-container'>
                            <h2 className="alt-header mb-5 text-center"><span className='underline'>Purring Customers</span></h2>
                            <div className="purring-customers-wrapper">
                                <div className="slider-wrapper">
                                    <div className="swiper teamSwiper stats">
                                        <Swiper
                                            className='w-100'
                                            modules={[Navigation, Pagination]}
                                            speed={700}
                                            slidesPerView={1}
                                            spaceBetween={20}
                                            slidesPerGroup={1}
                                            loop={false}
                                            loopFillGroupWithBlank={true}
                                            breakpoints={{
                                            768: {
                                                slidesPerView: 3,
                                                slidesPerGroup: 3,
                                            },
                                            620: {
                                                slidesPerView: 2,
                                                slidesPerGroup: 2,
                                            }
                                            }}
                                            pagination={{
                                            el: ".purring-customers-wrapper .swiper-pagination",
                                            clickable: true,
                                            }}
                                            navigation={{
                                            nextEl: ".purring-customers-wrapper .swiper-button-next",
                                            prevEl: ".purring-customers-wrapper .swiper-button-prev",
                                            }}
                                        >
                                            <SwiperSlide>
                                                <Card className='overflow-hidden h-100'>
                                                    <img style={{width: '100%'}} src="/marketing-assets/images/purring-customers/cheque-1.png" alt="purring customer" />
                                                    <div className='p-3 d-flex flex-column justify-content-between h-100'>
                                                        <p className="font-graphik text-start">
                                                            “<strong>~$5000 more</strong> than an estimated trade-in or buy-out range”
                                                        </p>
                                                        <div className='d-flex flex-column align-items-start gap-1'>
                                                            <div className='rating-stars'>
                                                                {starIcon}
                                                                {starIcon}
                                                                {starIcon}
                                                                {starIcon}
                                                                {starIcon}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Card className='overflow-hidden h-100'>
                                                    <img style={{width: '100%'}} src="/marketing-assets/images/purring-customers/cheque-2.png" alt="purring customer" />
                                                    <div className='p-3 d-flex flex-column justify-content-between h-100'>
                                                        <p className="font-graphik text-start">
                                                            “When you don't have the time or care to sell yourself, choose PURR!”
                                                        </p>
                                                        <div className='d-flex flex-column align-items-start gap-1'>
                                                            <div className='rating-stars'>
                                                                {starIcon}
                                                                {starIcon}
                                                                {starIcon}
                                                                {starIcon}
                                                                {starIcon}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Card className='overflow-hidden h-100'>
                                                    <img style={{width: '100%'}} src="/marketing-assets/images/purring-customers/cheque-3.png" alt="purring customer" />
                                                    <div className='p-3 d-flex flex-column justify-content-between h-100'>
                                                        <p className="font-graphik text-start">
                                                            “PURR partners offer financing which can help get your vehicle sold faster and for more!”
                                                        </p>
                                                        <div className='d-flex flex-column align-items-start gap-1'>
                                                            <div className='rating-stars'>
                                                                {starIcon}
                                                                {starIcon}
                                                                {starIcon}
                                                                {starIcon}
                                                                {starIcon}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                    <div className="swiper-button-next"></div>
                                    <div className="swiper-button-prev"></div>
                                    <div className="swiper-pagination"></div>
                                </div>
                            </div>
                        </Container>
                    </section>
                    <section className='bg-grey challenges challenges-section'>
                        <Container fluid='lg' className='challenges-container'>
                            <div className="cat" data-path="purrcy/cozy">
                                <AnimatedComponent path="/marketing-assets/animations/purrcy/cozy.json"/>
                            </div>
                            <h2 className="alt-header mb-5">What <span className="underline">you'll love</span> about Purr</h2>
                            <div className="stats">
                                <div className="stats-item">
                                    <div>
                                        <h4>First time in history</h4>
                                        <img className="stat-image" src="/marketing-assets/images/icons/deal.svg"/>
                                        <p className="font-graphik">
                                            You're in control.  Set your price and allow the experts to do all the work and wait for your big cheque!
                                        </p>
                                    </div>
                                </div>
                                <div className="stats-item">
                                    <div>
                                        <h4>Sell faster and for more</h4>
                                        <img className="stat-image" src="/marketing-assets/images/icons/tag.svg"/>
                                        <p className="font-graphik">
                                            With financing options you open the market up to an additional ~46% of the population that prefer payments vs. paying cash.
                                        </p>
                                    </div>
                                </div>
                                <div className="stats-item">
                                    <div>
                                        <h4>Safe & Secure</h4>
                                        <img className="stat-image" src="/marketing-assets/images/icons/payment.svg"/>
                                        <p className="font-graphik">
                                            Our thoroughly vetted dealer partners ensure you a trusted, secure, and seamless transaction every time.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </section>
                    {latestVehicles?.length ? (
                        <section className='latest-vehicles-section bg-white'>
                            <Container fluid='lg'>
                                <div className='latest-vehicles-section-heading'>
                                    <h2 className='mb-0 h1'>Latest vehicles</h2>
                                    <div className='all-vehicles-navigation'>
                                        <Link legacyBehavior href="/marketplace"><span className='underline'>All vehicles</span></Link>
                                        {agendaRightArrow}
                                    </div>
                                </div>
                                <div className="latest-vehicles-wrapper">
                                    <div className="slider-wrapper">
                                        <div className="swiper teamSwiper stats">
                                            <Swiper
                                                className='w-100'
                                                modules={[Navigation, Pagination]}
                                                speed={700}
                                                slidesPerView={1}
                                                slidesPerGroup={1}
                                                spaceBetween={10}
                                                loop={false}
                                                loopFillGroupWithBlank={true}
                                                breakpoints={{
                                                1200: {
                                                    slidesPerView: 4,
                                                    slidesPerGroup: 4,
                                                },
                                                992: {
                                                    slidesPerView: 3,
                                                    slidesPerGroup: 3,
                                                },
                                                620: {
                                                    slidesPerView: 2,
                                                    slidesPerGroup: 2,
                                                }
                                                }}
                                                pagination={{
                                                el: ".latest-vehicles-wrapper .swiper-pagination",
                                                clickable: true,
                                                }}
                                                navigation={{
                                                nextEl: ".latest-vehicles-wrapper .swiper-button-next",
                                                prevEl: ".latest-vehicles-wrapper .swiper-button-prev",
                                                }}
                                            >
                                                {latestVehicles?.map((listing: any) => (
                                                    <SwiperSlide key={listing.id}>
                                                        <ListingItem key={listing.id} listing={listing} />
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                        <div className="swiper-button-next"></div>
                                        <div className="swiper-button-prev"></div>
                                        <div className="swiper-pagination"></div>
                                    </div>
                                </div>
                                <div className='start-shopping'>
                                    <Link legacyBehavior href="/marketplace"><span>Start shopping</span></Link>
                                </div>
                            </Container>
                        </section>
                    ) : null}
                    <section className='navigation-section'>
                        <Container className='d-flex'>
                            <div className="navigation-section-item">
                                <h2 className="alt-header text-center">I want to <span className='underline'>buy a car</span></h2>
                                <div className='navigation-actions'>
                                    <Link legacyBehavior href="/marketplace">Browse cars</Link>
                                    <Link legacyBehavior href="/buy">Learn more</Link>
                                </div>
                            </div>
                            <div className="navigation-section-item sell">
                                <h2 className="alt-header text-center">I want to <span className='underline white'>sell my car</span></h2>
                                <div className='navigation-actions sell'>
                                    <Link legacyBehavior href="/vehicle-lead">List your car</Link>
                                    <Link legacyBehavior href="/sell">Learn more</Link>
                                </div>
                            </div>
                        </Container>
                    </section>
                    <section className="bg-white buyer-how-it-works" id='how-it-works'>
                        <div className="container custom-container">
                            <h2 className="alt-header mb-5 mb-md-0 text-center"><span className="underline">How it works</span></h2>
                            <div className="how-it-works-container">
                                <div>
                                    <video controls poster="/marketing-assets/images/how-to-start-preview.png">
                                        <source src="/marketing-assets/videos/how_it_works.mp4"/>
                                    </video>
                                </div>
                                <div className="how-it-works-carousel-wrapper">
                                    <div className="slider-wrapper">
                                        <div className="swiper howItWorksSwiper stats">
                                            <Swiper
                                                className="w-100"
                                                modules={[Navigation, Pagination]}
                                                speed={700}
                                                slidesPerView={1}
                                                spaceBetween={30}
                                                slidesPerGroup={1}
                                                loop={true}
                                                loopFillGroupWithBlank={true}
                                                pagination={{
                                                    el: '.how-it-works-carousel-wrapper .swiper-pagination',
                                                    clickable: true
                                                }}
                                                navigation={{
                                                    nextEl: '.how-it-works-carousel-wrapper .swiper-button-next',
                                                    prevEl: '.how-it-works-carousel-wrapper .swiper-button-prev'
                                                }}
                                            >
                                                <SwiperSlide>
                                                    <div className="slide-container">
                                                        <div className="texts-container">
                                                            <h3>First things first </h3>
                                                            <p className="font-graphik">
                                                                Sellers select “Sell” on purr.ca, add basic vehicle info and select a local Authorized Purr Dealer.
                                                            </p>
                                                            <img className="number"
                                                                src="/marketing-assets/images/how-it-works/numbers/Step1.svg"/>
                                                            <div className="illustration-container">
                                                                <img className="illustration"
                                                                    src="/marketing-assets/images/how-it-works/illustrations/01.png"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="slide-container">
                                                        <div className="texts-container">
                                                            <h3>The local Dealer works their magic</h3>
                                                            <p className="font-graphik">
                                                                An Authorized Purr Dealer inspects the vehicle,
                                                                takes professional photos, and publishes the listing to the Purr marketplace.
                                                            </p>
                                                            <img className="number"
                                                                src="/marketing-assets/images/how-it-works/numbers/Step2.svg"/>
                                                            <div className="illustration-container">
                                                                <img className="illustration"
                                                                    src="/marketing-assets/images/how-it-works/illustrations/02.png"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="slide-container">
                                                        <div className="texts-container">
                                                            <h3>Buyers shop the Purr marketplace.</h3>
                                                            <p className="font-graphik">
                                                                Buyers visit purr.ca to shop exclusive inventory and
                                                                schedule their test drive with an Authorized Purr Dealer.
                                                            </p>
                                                            <img className="number"
                                                                src="/marketing-assets/images/how-it-works/numbers/Step3.svg"/>
                                                            <div className="illustration-container">
                                                                <img className="illustration"
                                                                    src="/marketing-assets/images/how-it-works/illustrations/03.png"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="slide-container">
                                                        <div className="texts-container">
                                                            <h3>Test drives with experts</h3>
                                                            <p className="font-graphik">
                                                                Potential buyers meet with sales experts at the dealership
                                                                to test drive cars and discuss dealership purrks.
                                                            </p>
                                                            <img className="number"
                                                                src="/marketing-assets/images/how-it-works/numbers/Step4.svg"/>
                                                            <div className="illustration-container">
                                                                <img className="illustration"
                                                                    src="/marketing-assets/images/how-it-works/illustrations/04.png"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="slide-container">
                                                        <div className="texts-container">
                                                            <h3>Simplest negotiations</h3>
                                                            <p className="font-graphik">
                                                                Buyers and sellers negotiate directly with each other to agree upon the purrfect price.
                                                            </p>
                                                            <img className="number"
                                                                src="/marketing-assets/images/how-it-works/numbers/Step5.svg"/>
                                                            <div className="illustration-container">
                                                                <img className="illustration"
                                                                    src="/marketing-assets/images/how-it-works/illustrations/05.png"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="slide-container">
                                                        <div className="texts-container">
                                                            <h3>Trusted  transactions</h3>
                                                            <p className="font-graphik">
                                                                The Authorized Purr dealer handles all the paperwork and secures payment to pay the seller.
                                                            </p>
                                                            <img className="number"
                                                                src="/marketing-assets/images/how-it-works/numbers/Step6.svg"/>
                                                            <div className="illustration-container">
                                                                <img className="illustration"
                                                                    src="/marketing-assets/images/how-it-works/illustrations/06.png"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                    <div className="slide-container">
                                                        <div className="texts-container">
                                                            <h3>A purrfect experience</h3>
                                                            <p className="font-graphik">
                                                                The seller gets the best value for their vehicle. The buyer drives away, purring.
                                                                The Authorized Purr Dealer gets a happy buyer and a happy seller!
                                                                <strong> At Purr, everybody wins. It’s so simple.</strong>
                                                            </p>
                                                            <img className="number"
                                                                src="/marketing-assets/images/how-it-works/numbers/Step7.svg"/>
                                                            <div className="illustration-container">
                                                                <img className="illustration"
                                                                    src="/marketing-assets/images/how-it-works/illustrations/07.png"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>
                                        </div>
                                        <div className="buttons-container">
                                            <div className="swiper-button-next"></div>
                                            <div className="swiper-button-prev"></div>
                                            <div className="swiper-pagination"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='buyer-seller-benefits-section bg-grey'>
                        <Container>
                            <div className="buyer-seller-benefits-section-item">
                                <h2 className="mb-5 h1"><span className='underline'>Buyer benefits</span></h2>
                                <div className='d-flex align-items-start gap-3'>
                                    <img className="stat-image" width={46} src="/marketing-assets/images/icons/badge.svg"/>
                                    <div>
                                        <h4>Private market pricing. Dealership purrks.</h4>
                                        <p className="font-graphik">
                                            Access private market inventory and get dealership purrks, like financing, extended
                                            warranties, repairs, and more.
                                        </p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-start gap-3'>
                                    <img className="stat-image" width={46} src="/marketing-assets/images/icons/tag.svg"/>
                                    <div>
                                        <h4>Your car. Your price.</h4>
                                        <p className="font-graphik">
                                            Browse the growing Purr marketplace, schedule a test drive,
                                            and purchase your car for a fair price.
                                        </p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-start gap-3'>
                                    <img className="stat-image" width={46} src="/marketing-assets/images/icons/keys.svg"/>
                                    <div>
                                        <h4>Your personal buying expert.</h4>
                                        <p className="font-graphik">
                                            For the first time you can access private sales inventory,
                                            with the security of a personal buying expert—your Authorized Purr Dealer.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="buyer-seller-benefits-section-item">
                                <h2 className="mb-5 h1"><span className='underline'>Seller benefits</span></h2>
                                <div className='d-flex align-items-start gap-3'>
                                    <img className="stat-image" width={46} src="/marketing-assets/images/icons/negotiation.svg"/>
                                    <div>
                                        <h4>Simplest negotiations.</h4>
                                        <p className="font-graphik">
                                            With Purr, you negotiate directly with the buyer on the Purr platform.
                                        </p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-start gap-3'>
                                    <img className="stat-image" width={46} src="/marketing-assets/images/icons/payment.svg"/>
                                    <div>
                                        <h4>Get paid quickly and simply.</h4>
                                        <p className="font-graphik">
                                            No need to chase buyers for your money. Once you agree on the price,
                                            the dealer will close the sale with the buyer, and you simply pick up the cheque when it's all done!
                                        </p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-start gap-3'>
                                    <img className="stat-image" width={46} src="/marketing-assets/images/icons/shield.svg"/>
                                    <div>
                                        <h4>The safest sale.</h4>
                                        <p className="font-graphik">
                                            No sketchy stranger meetups. Leave the car with the dealer, and their sales staff handle the leads for you!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </section>
                    {/* <section className='press-release-section'>
                        <div className='press-release-wrapper'>
                            <h4 className='mb-0'>Have you seen our <span className='underline white'>press release?</span></h4>
                            <Link legacyBehavior href="/press-release">Read article</Link>
                        </div>
                    </section> */}
                </div>
            </MarketingLayout>
        </>
    )
}


export default App;
